<template>
  <div class="UserAssignment">
    <h3>用户分配</h3>
    <!-- 配置负责人 -->
    <distribution v-if="isShow" @closeDialog="closeDialog" ref="isShow" />
    <!-- 根据条件搜索    -->
    <div class="searchItem">
      <el-form :model="form" label-position="left" label-width="80px" inline ref="form">
        <el-form-item label="用户名：">
          <el-input style="width: 190px" v-model="form.userName" placeholder="用户名">
          </el-input>
        </el-form-item>
        <el-form-item label="负责人：">
          <el-input style="width: 190px" v-model="form.accountting" placeholder="负责人">
          </el-input>
        </el-form-item>
        <el-form-item label="所在地：">
          <el-cascader style="width: 190px" ref="cascader" v-model="form.areaCode" :props="props" :options="options"
            :show-all-levels="false"></el-cascader>
        </el-form-item>
        <el-form-item label="分配状态：">
          <el-select v-model="form.status" style="width: 200px">
            <el-option v-for="(item, index) in types" :key="index" :value="item.value"
              :label="item.label"></el-option></el-select>
        </el-form-item>
        <div>
          <el-button class="query-btn" type="primary" @click="search">搜索</el-button>
          <el-button class="query-btn" type="primary" @click="AllCheck()">批量配置</el-button>
        </div>
      </el-form>
    </div>
    <!-- table列表 -->
    <div class="TableList">
      <table-page :data="tableData" :columns="columns" :currentPage="query.pnum" :pageSize="query.psize" :total="total"
        :loading="loading" :border="true" @handSelect="handSelect" @handSelectAll="handSelectAll"
        @sizeChange="handleSizeChange" @currentChange="handleCurrentChange">
        <template slot="allCheck" slot-scope="scope">
          {{ scope.row.applyId }}
        </template>

        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="detailClick(scope.row)">配置负责人</el-button>
        </template>
      </table-page>
    </div>
  </div>
</template>

<script>
  import TablePage from "./component/table-page";
  import distribution from "./component/distribution";
  import { AreaTree } from "@/api/RuralFinance";
  import { mapGetters } from "vuex";
  import { UserAssignment } from "../tableColumn";
  import { allocationAccountting } from "@/api/ChargeUp";

  export default {
    data() {
      return {
        types: [
          {
            label: "全部",
            value: "0",
          },
          {
            label: "已分配",
            value: "2",
          },
          {
            label: "未分配",
            value: "1",
          },
        ],
        form: {
          userName: "", //用户名
          accounting: "", //负责人
          areaCode: "", //所在地
          status: "", //分配状态
        }, //搜索条件
        options: [], // 所在地区
        props: {
          label: "areaName",
          children: "children",
          value: "id",
          fullPathName: "fullPathName",
          checkStrictly: true,
          emitPath: false,
        },
        tableData: [], //table数据
        columns: UserAssignment, //table表头
        query: {
          pnum: 1,
          psize: 10,
        }, //分页
        total: 0, //数量
        loading: false, //加载状态
        IsIncome: false, //收入或者支出的弹窗
        checked: "", //选项
        isShow: false, //是否打开负责人弹窗
        ConfigurationList: [], //配置列表
      };
    },
    computed: {
      ...mapGetters(["topSelectAreacode", "identityObj"]),
    },

    components: {
      TablePage,
      distribution,
    },
    mounted() {
      //查询所在地址
      this.AddressList();
      //初始化需求列表
      this.getList();
    },

    methods: {
      // 查看需求列表
      async getList() {
        let params = {
          pnum: this.query.pnum,
          psize: this.query.psize,
        };
        let res = await allocationAccountting(params);
        this.tableData = res.data;
        this.total = Number(res.dataNum);
      },
      //分页总页数切换
      handleSizeChange(size) {
        this.query = {
          pnum: 1,
          psize: size,
        };
        this.getList();
      },
      handleCurrentChange(page) {
        console.log(page, '查看page是多少')
        this.query.pnum = page;
        this.getList();
      },
      //进行搜索
      async search() {
        let params = {
          ...this.form,
          ...this.query,
        };
        let res = await allocationAccountting(params);
        this.tableData = res.data;
      },
      //查询所在地址
      async AddressList() {
        let params = {
          parentCode: this.identityObj.areaCode,
        };
        let res = await AreaTree(params);
        this.options = res.data;
      },
      //关闭支付凭证预览
      closeDialog(isShow) {
        this.isShow = isShow;
        this.getList();
      },
      //切换所有选项
      toggleAllSelection(val) {
        console.log(val);
      },
      // 全选或者反选
      handSelectAll(selection) {
        this.ConfigurationList = selection;
        console.log(selection, "全选或者反选的数据");
      },
      //多选
      handSelect(selection) {
        this.ConfigurationList = selection;
        console.log(selection, "多选的数据");
      },
      //配置负责人
      detailClick(item) {

        this.isShow = true;
        this.$nextTick(() => {
          this.$refs.isShow.init(Array(item));
        });
      },
      //批量分配
      AllCheck() {
        if (this.ConfigurationList.length > 0) {
          this.isShow = true;
          this.$nextTick(() => {
            this.$refs.isShow.init(this.ConfigurationList);
          });
        } else {
          this.$message.info("请选择需要配置的需求");
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .UserAssignment {

    /*根据条件搜索*/
    .searchItem {
      padding-left: 20px;

      .el-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      /deep/.el-form-item {
        width: 25% !important;

        .el-input {
          width: 200px;
        }

        .el-form-item__label {
          width: 100px !important;
        }

        /deep/.el-form-item__content {
          width: 150px;
        }

        .el-date-editor {
          width: 200px;
        }
      }
    }
  }
</style>